import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import { Document, Packer, Paragraph } from 'docx'; // For DOCX creation
import './PdfOcr.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

import eg1 from '../../assets/watermakPdf/orc.png'

const images = [
  { title: 'Pdf OCR', url: eg1 },
];


const ExampleModal = ({ isOpen, onClose, image }) => {
  if (!isOpen || !image) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-slider">
        <h3>{image.title}</h3>
        <img src={image.url} alt={image.title} style={{ width: '100%', height: 'auto' }} />
        <button
          onClick={onClose}
          style={{ marginTop: 20, backgroundColor: 'green', color: '#fff', padding: '8px 12px', border: 'none', borderRadius: '5px', fontSize: '16px' }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const PdfOcr = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [ocrFileUrl, setOcrFileUrl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ocrLanguage, setOcrLanguage] = useState('eng'); // Default to English
  const [toolPrice, setToolPrice] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation
  const [selectedImage, setSelectedImage] = useState(null);


  const APYHUB_API_URL = 'https://api.apyhub.com/ai/document/extract/read/file';
  const APYHUB_TOKEN = 'APY0NH5qRtdKjBXfvhrwoWjOh8JGc58qZjDB1gmHTen9UHdLFgF1QvffEX9L8YcXCdHiatBTLo9MgK';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'PDF OCR'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setOcrFileUrl(null);
    setErrorMessage(null);
    setIsCopied(false);
    setConfirmationMessage('');
  };

  const handleOcrLanguageChange = (event) => {
    setOcrLanguage(event.target.value);
  };

  const confirmProcess = () => {
    setIsModalOpen(true); // Open confirmation modal
  };

  const handleOcr = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file for OCR.');
      return;
    }
  
    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false);
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('requested_service', 'apyhub'); // Defaulting to ApyHub
  
      const response = await fetch('https://api.apyhub.com/ai/document/extract/read/file', {
        method: 'POST',
        headers: {
          'apy-token': 'APY0NH5qRtdKjBXfvhrwoWjOh8JGc58qZjDB1gmHTen9UHdLFgF1QvffEX9L8YcXCdHiatBTLo9MgK',
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`OCR failed with status ${response.status}`);
      }
  
      const result = await response.json();
      let extractedText = result.data.apyhub.content || "No text found";
      // Clean up the text by replacing escape sequences and control characters
      extractedText = extractedText.replace(/\\n/g, '\n').replace(/\\'/g, "'");
  
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({ text: extractedText }),
            ],
          },
        ],
      });
  
      const docxBlob = await Packer.toBlob(doc);
      const downloadUrl = await uploadDocxToFirebase(docxBlob);
      setOcrFileUrl(downloadUrl);
      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during OCR processing. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };
  
  
  const uploadDocxToFirebase = async (docxBlob) => {
    try {
      const fileName = `ocr_docx_${Date.now()}.docx`;
      const storageRef = ref(storage, `ocrDocs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, docxBlob); // Upload the DOCX file to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref);
  
      // Store the download URL in Firestore
      await addDoc(collection(db, 'ocrDocs'), {
        downloadUrl,
        timestamp: new Date(),
      });
  
      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload DOCX to Firebase.');
    }
  };
  
  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!ocrFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(ocrFileUrl);
      setIsCopied(true); // Set to true when the link is copied
      setConfirmationMessage('Converted DOCX link copied to clipboard.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

    // Open the modal with the clicked image
    const handleImageClick = (image) => {
      setSelectedImage(image);
      setIsModalOpen(true);
    };
    

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="ocr-pdf-container">
      <h1>PDF OCR</h1>
      <p>Convert scanned PDF to copiable text using OCR technology.</p>
      <div className="grid-container">
          {images.map((image, index) => (
            <div key={index} className="grid-item" onClick={() => handleImageClick(image)}>
              <img src={image.url} alt={image.title} className="grid-image" />
              <p>{image.title}</p>
            </div>
          ))}
        </div>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept=".pdf"  // Only allow PDF files
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select PDF file
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-preview-container">
          <div className="pdf-preview">
              <div className="pdf-thumbnail">
                <span>FileName: {selectedFile.name}</span>
                <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
              </div>
            </div>

            {/* <div className="ocr-language-container">
            <label htmlFor="ocr-language">OCR Language:</label>
            <select 
              id="ocr-language" 
              value={ocrLanguage} 
              onChange={handleOcrLanguageChange}
            >
              <option value="eng">English</option>
              <option value="tam">Tamil</option>
              <option value="tel">Telugu</option>
              <option value="mal">Malayalam</option>
              <option value="kan">Kannada</option>
              <option value="hin">Hindi</option>
              <option value="spa">Spanish</option>
              <option value="fra">French</option>
              <option value="deu">German</option>
            </select>
          </div> */}

          <button 
            className="ocr-button" 
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Start OCR'}
          </button>
        </div>
      )}

<ExampleModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} image={selectedImage} />


      {ocrFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for OCR processing?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleOcr}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default PdfOcr;
